import React, { useState, useContext } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
  useMediaQuery,
  useTheme,
  Divider,
  Chip,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";

import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';

import DeleteIcon from "@mui/icons-material/Delete";
import AuthContext from "../../context/AuthContext";
import { enrollmentTerminal } from "../../services/terminal/enrollmentTerminalService";
import { processFile, sendData  } from "../../utils/excelValidatorAndSend"; 

const FormComponent = ({ setSnackOpen, setMessageOpen, setSeverity }) => {
  const { token, acquirerData } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    acquirer: {
      iso: "",
      isoID: "",
      currency: "",
      holding: "",
      holdingId: "",
      group: "",
      groupId: "",
      name: "",
      store: "",
      address: "",
      neighborhood: "",
      city: "",
      merchantState: "",
      postalCode: "",
      mccID: "",
      merchantID: "",
      amountOther: "",
      terminal: {
        terminals: [{ terminalID: "", terminalType: "", terminalSerial: "" }],
      }
    },
    merchant: {
      legal_name:"",
      business_email: "",
      business_phone: "",
      business_type: "",
      taxpayer_id: "",
      incorporation_date: "",
      effective_date: "",
      amount_tx_processing: "",
      amount_tx_processing_tiers: "",
      percent_tx_processing: "",
      percent_tx_processing_tiers: "",
      amount_terminal_maintenance: "",
      amount_terminal_maintenance_tiers: "",
      terminal_maintenance_inactive: "",
      amount_terminal_setup: "",
      amount_terminal_setup_tiers: "",
    },
    bank: {
      bid: "",
      swift_code: "",
      account_number: "",
      address: "",
      routing_number: "",
      iban: "",
      branch_number: "",
      city: "",
      state: "",
      country_code: "",
      postal_code: "",
    },
    owner: [{
      first_name: "",
      last_name: "",
      middle_name: "",
      id: "",
      taxpayer_id: "",
      percentage: "",
      id_issuer: "",
      personal_email: "",
      personal_phone: "",
      address: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      neighborhood: "",
      residency_time: "",
    }],
  });

  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successfulData, setSuccessfulData] = useState([]);
  const [failedData, setFailedData] = useState([]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      acquirer: {
        ...prevState.acquirer,
        [name]: value,
      },
    }));
  };
  const handleInputChangeMerchant = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      merchant: {
        ...prevState.merchant,
        [name]: value,
      },
    }));
  };
  const handleInputChangeBank = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      bank: {
        ...prevState.bank,
        [name]: value,
      },
    }));
  };

  const handleTerminalChange = (index, field, value) => {
    const updatedTerminals = [...formData.acquirer.terminal.terminals];
    updatedTerminals[index][field] = value;
    setFormData((prev) => ({
      ...prev,
      acquirer: {
        ...prev.acquirer,
        terminal: {
          ...prev.acquirer.terminal,
          terminals: updatedTerminals,
        },
      },
    }));
  };  

  const handleAddTerminal = () => {
    setFormData((prev) => ({
      ...prev,
      acquirer: {
        ...prev.acquirer,
        terminal: {
          ...prev.acquirer.terminal,
          terminals: [
            ...prev.acquirer.terminal.terminals,
            { terminalID: "", terminalType: "", terminalSerial: "" },
          ],
        },
      },
    }));
  };

  const handleDeleteTerminal = (index) => {
    setFormData((prev) => ({
      ...prev,
      acquirer: {
        ...prev.acquirer,
        terminal: {
          ...prev.acquirer.terminal,
          terminals: prev.acquirer.terminal.terminals.filter((_, i) => i !== index),
        },
      },
    }));
  };
  
  const handleAddOwner = () => {
    setFormData((prev) => ({
      ...prev,
      owner: [...prev.owner, fields_owners.reduce((acc, field) => {
        acc[field.name] = "";
        return acc;
      }, {})],
    }));
  };
  
  const handleOwnerChange = (index, field, value) => {
    const updatedOwners = [...formData.owner];
    updatedOwners[index][field] = value;
    setFormData((prev) => ({
      ...prev,
      owner: updatedOwners,
    }));
  };
  
  const handleDeleteOwner = (index) => {
    setFormData((prev) => ({
      ...prev,
      owner: prev.owner.filter((_, i) => i !== index),
    }));
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  const processFileUpload = () => {
    const url = "/enrollment";
    if (file) {
      processFile(file)
        .then(({ filteredCellData }) => {

          sendData(filteredCellData, url, token, acquirerData)
            .then(({ successfulData, failedData }) => {

              setSuccessfulData(successfulData);
              setFailedData(failedData);

              setIsModalOpen(true);
            })
            .catch((error) => {
              console.error("Error sending data:", error);
            });
        })
        .catch((error) => {
          console.error("Error processing the file:", error);
        });
    } else {
      console.error("No files selected for upload.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = "/enrollment";

      const response = await enrollmentTerminal(url, formData, token, acquirerData);
      setSnackOpen(response.data?.holdings?.holding.length > 0 ? false : true);
      setMessageOpen("Merchant Terminal successfully saved!");
      setSeverity("success");
    } catch (error) {
      console.error(error);
      let errorMessage = "Merchant Terminal NOT saved!";

      if (error.request?.responseText) {
        try {
          const response = JSON.parse(error.request.responseText); 
          if (response.detail) {
            errorMessage += `: ${response.detail}`; 
          }
        } catch (parseError) {
          console.error("Failed to parse error.responseText:", parseError);
          errorMessage += `: ${error.request.responseText}`;
        }
      }
      setSeverity("error");
      setMessageOpen(errorMessage); 
      setSnackOpen(true); 
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fields_acquirer = [
    { label: "ISO", name: "iso", type: "select", options: ["True", "False"], shrink: true, maxLength:3 },
    { label: "ISO ID", name: "isoID", type: "text", shrink: true, maxLength:4 },
    { label: "Currency Code", name: "currency", type: "select", options: ["BRL", "USD"], shrink: true, maxLength:5 },
    { label: "MCC ID", name: "mccID", type: "text", shrink: true, maxLength:4 },
    { label: "Amount Other", name: "amountOther", type: "select", options: ["True", "False"], shrink: true, maxLength:3 },
    { label: "Holding", name: "holding", type: "text", shrink: true, maxLength:16 },
    { label: "Holding ID", name: "holdingId", type: "text", shrink: true, maxLength:16 },
    { label: "Group", name: "group", type: "text", shrink: true, maxLength:16 },
    { label: "Group ID", name: "groupId", type: "text", shrink: true, maxLength:16 }, 
    { label: "Merchant Name", name: "name", type: "text", shrink: true, maxLength:16 },
    { label: "Store", name: "store", type: "text", shrink: true, maxLength:8 },
    { label: "Address", name: "address", type: "text", shrink: true, maxLength:32 },
    { label: "Merchant ID", name: "merchantID", type: "text", shrink: true, maxLength:16 },
    { label: "Neighborhood", name: "neighborhood", type: "text", shrink: true, maxLength:32 },
    { label: "City", name: "city", type: "text", shrink: true, maxLength:32 },
    { label: "State", name: "merchantState", type: "text", shrink: true, maxLength:32 },
    { label: "Postal Code", name: "postalCode", type: "text", shrink: true, maxLength:16 },
  ];

  const fields_merchant = [
    //{ label: "Incorporation Type", name: "iso", type: "select", options: ["True", "False"], shrink: true, maxLength:3 },
    { label: "Legal Name", name: "legal_name", type: "text", shrink: true, maxLength:16 },
    { label: "Business Email", name: "business_email", type: "text", shrink: true, maxLength:16 },
    { label: "Business Phone", name: "business_phone", type: "text", shrink: true, maxLength:16 },
    { label: "Business Type", name: "business_type", type: "text", shrink: true, maxLength:16 },
    { label: "Taxpayer ID", name: "taxpayer_id", type: "text", shrink: true, maxLength:16 },
    { label: "Incorporation Date", name: "incorporation_date", type: "text", shrink: true, maxLength:16 },
    { label: "Effective Date", name: "effective_date", type: "text", shrink: true, maxLength:16 },

    { label: "Amount TX Processing", name: "amount_tx_processing", type: "text", shrink: true, maxLength:32 },
    { label: "Amount TX Processing Tiers", name: "amount_tx_processing_tiers", type: "text", shrink: true, maxLength:32 },
    { label: "Percent TX Processing", name: "percent_tx_processing", type: "text", shrink: true, maxLength:6 },
    { label: "Percent TX Processing Tiers", name: "percent_tx_processing_tiers", type: "text", shrink: true, maxLength:32 },
    { label: "Amount Terminal Maintenance", name: "amount_terminal_maintenance", type: "text", shrink: true, maxLength:32 },
    { label: "Amount Terminal Maintenance Tiers", name: "amount_terminal_maintenance_tiers", type: "text", shrink: true, maxLength:32 },
    { label: "Terminal Maintenance Inactive", name: "terminal_maintenance_inactive", type: "select", options: ["True", "False"], shrink: true, maxLength:5 },
    { label: "Amount Terminal Setup", name: "amount_terminal_setup", type: "text", shrink: true, maxLength:32 },
    { label: "Amount Terminal Setup Tiers", name: "amount_terminal_setup_tiers", type: "text", shrink: true, maxLength:32 },
  ];

  const fields_bank = [
    //{ label: "Incorporation Type", name: "iso", type: "select", options: ["True", "False"], shrink: true, maxLength:3 },
    { label: "Bid", name: "bid", type: "text", shrink: true, maxLength:16 },
    { label: "SWIFT Code", name: "swift_code", type: "text", shrink: true, maxLength:16 },
    { label: "Account Number", name: "account_number", type: "text", shrink: true, maxLength:16 },
    { label: "Bank Address", name: "address", type: "text", shrink: true, maxLength:32 },
    { label: "Routing Number", name: "routing_number", type: "text", shrink: true, maxLength:16 },
    { label: "IBAN", name: "iban", type: "text", shrink: true, maxLength:40 },
    { label: "Branch Number", name: "branch_number", type: "text", shrink: true, maxLength:16 },
    { label: "City", name: "city", type: "text", shrink: true, maxLength:16 },
    { label: "State", name: "state", type: "text", shrink: true, maxLength:16 },
    { label: "Country Code", name: "country_code", type: "text", shrink: true, maxLength:16 },
    { label: "Postal Code", name: "postal_code", type: "text", shrink: true, maxLength:16 },
  ];

  const fields_owners = [
    //{ label: "Incorporation Type", name: "iso", type: "select", options: ["True", "False"], shrink: true, maxLength:3 },
    { label: "First Name", name: "first_name", type: "text", shrink: true, maxLength:16 },
    { label: "Last Name", name: "last_name", type: "text", shrink: true, maxLength:16 },
    { label: "Middle Name", name: "middle_name", type: "text", shrink: true, maxLength:16 },
    { label: "ID", name: "id", type: "text", shrink: true, maxLength:16 },
    { label: "Taxpayer ID", name: "taxpayer_id", type: "text", shrink: true, maxLength:16 },
    { label: "Percentage", name: "percentage", type: "text", shrink: true, maxLength:16 },
    { label: "ID Issuer", name: "id_issuer", type: "text", shrink: true, maxLength:16 },
    { label: "Personal Email", name: "personal_email", type: "text", shrink: true, maxLength:16 },
    { label: "Personal Phone", name: "personal_phone", type: "text", shrink: true, maxLength:16 },
    { label: "Address", name: "address", type: "text", shrink: true, maxLength:16 },
    { label: "City", name: "city", type: "text", shrink: true, maxLength:16 },
    { label: "State", name: "state", type: "text", shrink: true, maxLength:16 },
    { label: "Country", name: "country", type: "text", shrink: true, maxLength:16 },
    { label: "Postal Code", name: "postal_code", type: "text", shrink: true, maxLength:16 },
    { label: "Neighborhood", name: "neighborhood", type: "text", shrink: true, maxLength:16 },
    { label: "Residency Time", name: "residency_time", type: "text", shrink: true, maxLength:16 },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            padding: 4,
            maxWidth: 600,
            maxHeight: "80vh",
            overflowY: "auto",
            borderRadius: 3,
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
            Validating file structure
          </Typography>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Merchant</TableCell>
                  <TableCell>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {successfulData.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>Success</TableCell>
                    <TableCell>{entry.item.acquirer.merchantID}</TableCell> 
                    <TableCell>Merchant Terminal successfully saved!</TableCell> 
                  </TableRow>
                ))}
                {failedData.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>Error</TableCell>
                    <TableCell>{entry.item.acquirer.merchantID}</TableCell> 
                    <TableCell>
                      {entry.error.response.request.response && 
                        typeof entry.error.response.request.response === 'string' ? 
                        entry.error.response.request.response.replace(/[{}"]/g, '') : 
                        'Unknown Error'}
                    </TableCell> 
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ marginTop: 2, textAlign: "center" }}>
            <Button onClick={handleCloseModal} variant="contained">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <Grid
        container
        spacing={isMobile ? 2 : 4}
        pb={isMobile ? "16px" : "32px"}
      > 
        <Grid item xs={12} sx={{ mt: 4, mb: 2 }}>
          <Divider variant="middle" component="li">
            <Chip variant="outlined" color="info" label="ACQUIRER INFO" />
          </Divider>
        </Grid>

        {fields_acquirer.map((field) => (
          (field.name === "isoID" && formData.acquirer.iso !== "True") ? null : (
            <Grid item xs={12} sm={3} key={field.name}>
              {field.type === "select" ? (
                <FormControl fullWidth required>
                  <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                  <Select
                    labelId={`${field.name}-label`}
                    name={field.name}
                    label={field.label}
                    value={formData.acquirer[field.name]}
                    onChange={handleInputChange}
                  >
                    {field.options.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  label={field.label}
                  name={field.name}
                  value={formData.acquirer[field.name]}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    shrink: field.shrink,
                  }}
                  inputProps={{
                    maxLength: field.maxLength,
                  }}
                  fullWidth
                />
              )}
            </Grid>
          )
        ))}


        <Grid item xs={12} sx={{ mt: 4, mb: 2 }}>
          <Divider variant="middle" component="li">
            <Chip variant="outlined" color="info" label="MENCHANT INFO" />
          </Divider>
        </Grid>

        {fields_merchant.map((field) => (
          <Grid item xs={12} sm={3} key={field.name}>
            {field.type === "select" ? (
              <FormControl fullWidth required>
                <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                <Select
                  labelId={`${field.name}-label`}
                  name={field.name}
                  label={field.label}
                  value={formData.merchant[field.name]}
                  onChange={handleInputChangeMerchant}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                label={field.label}
                name={field.name}
                value={formData.merchant[field.name]}
                onChange={handleInputChangeMerchant}
                InputLabelProps={{
                  shrink: field.shrink,
                }}
                inputProps={{
                  maxLength: field.maxLength,
                }}
                fullWidth
                
              />
            )}
          </Grid>
        ))} 

        {formData.acquirer.iso === "True" && (
          <>
            {/* BANK INFO */}
            <Grid item xs={12} sx={{ mt: 4, mb: 2 }}>
              <Divider variant="middle" component="li">
                <Chip variant="outlined" color="info" label="BANK INFO" />
              </Divider>
            </Grid>

            {fields_bank.map((field) => (
              <Grid item xs={12} sm={3} key={field.name}>
                {field.type === "select" ? (
                  <FormControl fullWidth required>
                    <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                    <Select
                      labelId={`${field.name}-label`}
                      name={field.name}
                      label={field.label}
                      value={formData.bank[field.name]}
                      onChange={handleInputChangeBank}
                    >
                      {field.options.map((option) => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    label={field.label}
                    name={field.name}
                    value={formData.bank[field.name]}
                    onChange={handleInputChangeBank}
                    InputLabelProps={{
                      shrink: field.shrink,
                    }}
                    inputProps={{
                      maxLength: field.maxLength,
                    }}
                    fullWidth
                  />
                )}
              </Grid>
            ))}

            {/*  OWNERS INFO */}
            <Grid item xs={12} sx={{ mt: 4, mb: 2 }}>
              <Box display="flex" alignItems="center">
                <Divider variant="middle" component="li" sx={{ flexGrow: 1 }}>
                  <Chip variant="outlined" color="info" label="OWNERS INFO" />
                </Divider>
                <Tooltip title="Add New Owner">
                  <IconButton color="primary" onClick={handleAddOwner} size="large" sx={{ ml: 2 }}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>

            {formData.owner.map((owner, index) => (
              <Grid item xs={12} key={index} sx={{ mt: 2 }}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                      <Typography variant="h6">Owner {index + 1}</Typography>
                      <Tooltip title="Remove Owner" arrow>
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteOwner(index)}
                          disabled={formData.owner.length === 1}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                  </Grid>

                  {fields_owners.map((field) => (
                    <Grid item xs={12} sm={3} key={field.name}>
                      {field.type === "select" ? (
                        <FormControl fullWidth required>
                          <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                          <Select
                            labelId={`${field.name}-label`}
                            name={field.name}
                            label={field.label}
                            value={owner[field.name] || ""}
                            onChange={(e) => handleOwnerChange(index, field.name, e.target.value)}
                          >
                            {field.options.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <TextField
                          label={field.label}
                          name={field.name}
                          value={owner[field.name] || ""}
                          onChange={(e) => handleOwnerChange(index, field.name, e.target.value)}
                          InputLabelProps={{
                            shrink: field.shrink,
                          }}
                          inputProps={{
                            maxLength: field.maxLength,
                          }}
                          fullWidth
                          required={field.required || false}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </>
        )}

        <Grid item xs={12} sx={{ mt: 4, mb: 2 }}>
          <Divider variant="middle" component="li">
            <Chip variant="outlined" color="info" label="TERMINALS" />
          </Divider>
        </Grid>


        {formData.acquirer.terminal.terminals.map((terminal, index) => (
          <Grid item xs={10} key={index}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  label="Terminal ID"
                  value={terminal.terminalID}
                  onChange={(e) =>
                    handleTerminalChange(index, "terminalID", e.target.value)
                  }
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="terminal-type-label">Terminal Type</InputLabel>
                  <Select
                    labelId="terminal-type-label"
                    value={terminal.terminalType}
                    label="Terminal Type"
                    onChange={(e) =>
                      handleTerminalChange(index, "terminalType", e.target.value)
                    }
                    required
                  >
                    <MenuItem value="A">ATM = A</MenuItem>
                    <MenuItem value="C">COTS (Phone/Tablet) = C</MenuItem>
                    <MenuItem value="E">eCommerce = E</MenuItem>
                    <MenuItem value="R">ECR = R</MenuItem>
                    <MenuItem value="I">App = I</MenuItem>
                    <MenuItem value="K">Kiosk = K</MenuItem>
                    <MenuItem value="M">mPOS = M</MenuItem>
                    <MenuItem value="P">POS = P</MenuItem>
                    <MenuItem value="T">Teller = T</MenuItem>
                    <MenuItem value="V">Vending Machine = V</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Terminal Serial"
                  value={terminal.terminalSerial}
                  onChange={(e) =>
                    handleTerminalChange(index, "terminalSerial", e.target.value)
                  }
                  fullWidth
                  required
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <IconButton
                  onClick={() => handleDeleteTerminal(index)}
                  disabled={formData.acquirer.terminal.terminals.length === 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid item xs={2} sx={{ alignSelf: "center" }}>
          <Button variant="contained" onClick={handleAddTerminal}>
            Add Terminal
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 2, marginTop: 2 }}
        >
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>

          {!file && (
            <>
              <input
                accept=".xls, .xlsx"
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" component="span">
                  Upload Excel File
                </Button>
              </label>
            </>
          )}

          {file && (
            <Button variant="contained" onClick={processFileUpload}>
              Process Upload
            </Button>
          )}

          <a
            id="template"
            href={`${process.env.PUBLIC_URL}/Merchant & Terminal.xlsx`}
            download
            style={{ textDecoration: "none", marginLeft: "16px" }}
          >
            <Button variant="outlined">Download Template</Button>
          </a>

        </Grid>

      </Grid>
    </form>
  );
};

export default FormComponent;