import React, { useState, useContext } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import AuthContext from "../../context/AuthContext";
import { fetchData } from "../../services/apiService";
import { getTerminalDetails } from "../../services/terminal/terminalService";
import { deleteTerminal } from "../../services/terminal/deleteTerminalService";
import TerminalDetails from "../TerminalDetails"; 

export default function SearchForm({ setSnackOpen, setMessageOpen, setSeverity }) {
  const { acquirerData, token } = useContext(AuthContext);
  const [searchFields, setSearchFields] = useState({ merchant: "" });
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [terminalDetails, setTerminalDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [terminalToDelete, setTerminalToDelete] = useState(null);
  const [displayedMerchant, setDisplayedMerchant] = useState("");
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleEdit = async (terminal) => {
    setLoadingDetails(true); 
    try {
      const details = await getTerminalDetails(terminal.acquirerTerminalID, token, acquirerData, searchFields );
      setTerminalDetails(details);  
    } catch (error) {
      setSnackOpen(true);
      setMessageOpen("Error fetching terminal details");
      setSeverity("error");
    } finally {
      setLoadingDetails(false); 
    }
  };

  const confirmDelete = (terminal) => {
    setTerminalToDelete(terminal);
    setDialogOpen(true);
  };

  const handleDelete = async () => {
    setLoadingDetails(true);
    try {
      await deleteTerminal(terminalToDelete.acquirerTerminalID, token, acquirerData, searchFields);
      setSnackOpen(true);
      setMessageOpen("Terminal deleted successfully");
      setSeverity("success");

      setResults((prevResults) =>
        prevResults.filter((item) => item.acquirerTerminalID !== terminalToDelete.acquirerTerminalID)
      );
    } catch (error) {
      setSnackOpen(true);
      setMessageOpen("Error deleting terminal");
      setSeverity("error");
    } finally {
      setLoadingDetails(false);
      setDialogOpen(false);
      setTerminalToDelete(null);
    }
  };

  const handleSearch = async () => {
    if (!searchFields.merchant) {
      setSnackOpen(true);
      setMessageOpen("Merchant is required");
      setSeverity("warning");
      return;
    }

    setResults([]);
    setLoading(true);

    try {
      const endpoint = "/terminals-list";
      const data = {
        version: "04.09.01",
        acquirer: {
          merchantID: searchFields.merchant,
        },
      };
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        acquirerName: acquirerData.acquirerName ,
        acquirerID: acquirerData.acquirerId ,
      };
      const response = await fetchData({ endpoint, data, headers, token });
      if (response.data?.acquirer?.terminals?.length) {
        setResults(response.data.acquirer.terminals);
        setDisplayedMerchant(searchFields.merchant);
        setSnackOpen(true);
        setMessageOpen("Search executed successfully");
        setSeverity("success");
      } else {
        setResults([]);
        setSnackOpen(true);
        setMessageOpen("No terminals found for the given merchant");
        setSeverity("info");
      }     
    } catch (error) {
      console.error(error);
      setSnackOpen(true);
      setMessageOpen("Error while fetching data");
      setSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center" mb={2}>
        <Grid item xs={12} sm={10}>
          <TextField
            fullWidth
            label="Merchant"
            name="merchant"
            value={searchFields.merchant}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSearch}
            disabled={loading}
          >
            {loading ? "Searching..." : "Search"}
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Merchant</TableCell>
              <TableCell>Terminal ID</TableCell>
              <TableCell>Terminal Type</TableCell>
              <TableCell>Terminal Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{displayedMerchant}</TableCell>
                <TableCell>{row.acquirerTerminalID}</TableCell>
                <TableCell>{row.terminalType}</TableCell> 
                <TableCell>{row.terminalStatus}</TableCell>
                <TableCell>
                  <ButtonGroup variant="outlined" size="small" aria-label="actions">
                    <Button
                      color="primary"
                      onClick={() => handleEdit(row)}
                    >
                      <EditIcon />
                    </Button>
                    <Button color="error" onClick={() => confirmDelete(row)}>
                      <DeleteIcon />
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
      {loadingDetails ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        terminalDetails && <TerminalDetails 
                              terminalDetails={terminalDetails}
                              setSnackOpen={setSnackOpen}
                              setMessageOpen={setMessageOpen}
                              setSeverity={setSeverity}
        />
      )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this terminal? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
